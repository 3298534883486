import { CoverBackground } from 'components/CoverBackground'
import { SearchForm } from 'components/Search'
import { ComponentType } from 'react'
import { FormattedMessage } from 'react-intl'
import { Col, Container, Row } from 'reactstrap'

export const HeroDesktop: ComponentType = () => (
  <div className="px-20">
    <CoverBackground url="https://dlljgdnxfi1ek.cloudfront.net/images/zeabletst9rmcdcbfasy.jpg" className="bg-regal-blue rounded-lg">
      <Container>
        <Row>
          <Col>
            <div className="pt-85 pb-235">
              <h1 className="font-family-open-sans font-weight-bold font-size-32 text-shadow text-center text-white mb-15">
                <FormattedMessage id="home.hero.title" />
              </h1>
              <h2 className="font-family-open-sans font-weight-bold font-size-base text-shadow text-center text-white">
                <FormattedMessage id="home.hero.subtitle" />
              </h2>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="py-55 rounded-lg-bottom bg-regal-blue-50">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="d-flex justify-content-center">
                <div className="p-8 rounded-lg bg-daintree-50">
                  <SearchForm />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </CoverBackground>
  </div>
)
