import { CoverBackground } from 'components/CoverBackground'
import { useStoreActions, useStoreState } from 'hooks/useStore'
import Link from 'next/link'
import { ComponentType } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'reactstrap'

export const HeroMobile: ComponentType = () => {
  const invader = useStoreState(store => store.ui.invader)
  const setInvader = useStoreActions(actions => actions.ui.setInvader)

  return (
    <CoverBackground
      url="https://dlljgdnxfi1ek.cloudfront.net/images/zeabletst9rmcdcbfasy-mobile.jpg"
      className="d-flex flex-column"
    >
      <div className="certificate-mobile">
        &#127942; Contamos con Asesores Certificados
      </div>
      <div className="px-45 flex-fill d-flex flex-column justify-content-center" style={{ marginTop: '80px' }}>
        <h1 className="mb-15 font-family-open-sans font-weight-600 font-size-32 text-shadow text-center text-white">
          <FormattedMessage id="home.hero.title" />
        </h1>
        <h2 className="mb-0 font-family-open-sans font-weight-600 font-size-base text-shadow text-center text-white">
          <FormattedMessage id="home.hero.subtitle" />
        </h2>
      </div>
      <div className="px-20 pb-40 bg-regal-blue-50">
        <div className="d-flex">
          <Link href="/explorar/operacion/[roleSlug]" as="/explorar/operacion/en-venta">
            <a title="Comprar" className="d-block py-24 flex-fill text-center text-white font-weight-600">
              Comprar
            </a>
          </Link>
          <Link href="/explorar/operacion/[roleSlug]" as="/explorar/operacion/en-renta">
            <a title="Rentar" className="d-block py-24 flex-fill text-center text-white font-weight-600">
              Rentar
            </a>
          </Link>
        </div>
        <div className="p-8 rounded-lg bg-daintree-50">
          <a
            title="Promover mi propiedad"
            href="https://wa.me/5219993351899"
            className="button-promote button-promote_mobile_hero font-size-14 font-weight-bold font-size-15"
          >
            Promover mi propiedad
          </a>
        </div>

      </div>
    </CoverBackground>
  )
}
